<template>
  <base-web-page>
    <template v-slot:main>
        <el-main style="padding:30px 20px;margin:0 auto;width:100%;max-width:907px;text-align:left;">
            <h1 style="text-align:center;">Privacy Policy</h1>
            <h4>Introduction</h4>
            <p class="p-content">Ucommune Pte. Ltd. (UEN : 201633328E) (“Company” or “Ucommune”) is committed to 
                protecting your Personal Data (as defined below) and this privacy policy (“Policy”) describes 
                how the Company collects, uses and discloses your Personal Data in connection with the use 
                of the Company’s website(s) (“Website Services“), applications (“Apps“), or other service 
                that refer to or links to this Policy, as well as any offline products, programs, courses, events 
                and services of the Company (collectively the “Services“). This Policy is subject to and 
                should be read in conjunction with the terms and conditions of the Membership Agreement 
                (as defined below). If you are an Employee (as defined below) or Applicant (as defined 
                below) who uses the Website Services, Apps and/or Services, this Policy explains how the 
                Company collects, uses and discloses your Personal Data in the context of your usage of the 
                aforesaid. 
            </p>
            <h4>Definitions and Interpretation</h4>
            <p class="p-content"><strong>Applicant</strong> means an applicant for employment with the Company. </p>
            <p class="p-content"><strong>Apps</strong> shall be as defined above. </p>
            <p class="p-content"><strong>Company or Ucommune</strong> shall be as defined above. </p>
            <p class="p-content"><strong>Employee</strong> means an employee of the Company.</p>
            <p class="p-content"><strong>Membership Agreement</strong> means the membership agreement entered into between any of the Company’s members and the Company. </p>
            <p class="p-content"><strong>Personal Data</strong> means data, whether true or not, about an individual who can be identified (a) 
                from that data; or (b) from that data and other information to which the Company has or is 
                likely to have access (as may be amended from time to time under the Personal Data 
                Protection Act 2012 (“PDPA”) ). 
            </p>
            <p class="p-content"><strong>Policy</strong> shall be as defined above.</p>
            <p class="p-content"><strong>Services</strong> shall be defined above and for the avoidance of doubt includes any services of the 
                Company and services set out in the Membership Agreement. 
            </p>
            <p class="p-content"><strong>Website Services</strong> shall be as defined above.</p>
            <p class="p-content"><strong>References to “you”</strong> means anyone who uses the Services, Website Services or Apps which 
                includes any Employee, Applicant and/or member as set out in the Membership Agreement 
                (references to <strong>“your”</strong> shall be construed accordingly). 
            </p>
            <h4>Information collected by the Company </h4>
            <p class="p-content">
                The Company may collect information from you including your Personal Data (such as name 
                of individual, phone number, date of birth, bank account, payment information, address, photographs, audio recordings and video recordings) through its website, by e-mail 
                correspondence, by postal mail or fax, over the telephone, in person or through other 
                documents. The information collected will vary depending on your relationship with the 
                Company but will be limited to that information necessary to record and manage its 
                relationship with you. In accordance with the PDPA, the Company will only collect Personal 
                Data where it is reasonably necessary for its functions or activities. 
            </p>
            <h4>Use of Personal Data</h4>
            <p>How the Company uses your Personal Data depends on your relationship with the Company. In general, the Company will use your Personal Data to: 
            </p>
            <ul class="p-content">
                <li>provide you with the Services, in order to meet its contractual obligations to you and 
                    to request for other materials;</li>
                <li>to consider and/or process your transaction with the Company or your transactions or 
                    communications with third parties;</li>
                <li>to manage, operate, provide and/or administer your use of and/or access to the 
                    Services including remembering your preference;</li>
                <li>to respond to, process, deal with or complete a transaction and/or to fulfil your 
                    requests for and to notify you of any issues </li>
                <li>contact you about the Services, promotions, events, updates and otherwise 
                    communicate with you. The Company generally relies on its legitimate interests in 
                    marketing the Services to you, whether on its own websites or on third party websites, 
                    and in providing the aforesaid, where permitted by applicable law;</li>
                <li>comply with its legal obligations including any reporting obligations required under 
                    any applicable Singapore law and regulation;</li> 
                <li>monitoring and reviewing the Company’s compliance with relevant regulations and 
                    codes of conduct in its dealings with you;</li> 
                <li>for the purposes of market research including but not limited to research for its 
                    business and branding;</li> 
                <li>managing recruitment for the Company; </li> 
                <li>automated data collection through interaction with its website; </li> 
                <li>protect against fraud, abuse or harm; </li> 
                <li>provide updates, alerts, information or communicating with you; and </li> 
                <li>purposes directly related or incidental to the above. </li> 
            </ul>
            <h4>Collection of Personal Data </h4>
            <p class="p-content">
                It is the Company’s usual practice to collect Personal Data directly from the individual or 
                their authorised representative. In event of collection of such Personal Data from an 
                authorised representative, it shall be deemed that the authorised representative has obtained 
                the consent of the subject for the disclosure of the Personal Data to the Company. 
            </p>
            <p class="p-content">
                At times, the Company may collect Personal Data from a third party or a publicly available 
                source, but only if an individual has consented to such collection or would reasonably expect 
                the Company to collect their Personal Data in this way. 
            </p>
            <p class="p-content">
                Your Personal Data is never shared, sold, rented or otherwise disclosed outside the Company 
                (except as required for any statutory, legal or ethical requirements) in ways different from 
                what is described in this Policy.
            </p>
            <h4>Disclosure of Personal Data to third parties</h4>
            <p class="p-content">
                To the extent possible, the Company aims to minimise disclosure of your Personal Data to 
                third parties. However, in order to effectively carry out its business or meet its contractual 
                obligations to you, the Company may disclose Personal Data to its related companies, service 
                providers and advertisers, and other end users, in order to provide its Services. 
            </p>
            <ul class="p-content">
                <li>The Company – the Company uses your Personal Data in accordance with this Policy 
                    in providing its Services including disclosure to personnel, agents, advisers, auditors, 
                    contractors, financial institutions, and service providers in connection with its 
                    operations (for example staff engaged in the fulfilment of any obligations, processing 
                    of payment and the provision of support services);</li> 
                <li>Community – the Services include online and offline communities, forums and 
                    networks that allow you to share information and connect with others. The Company 
                    makes this possible by creating profiles for members or permitting members to create 
                    their own profiles. Such a profile may contain your name and other information you 
                    provide. Depending on the Services you use, this profile might be created 
                    automatically and is accessible to others using the forum or network, some of which 
                    include the Company’s members. You can supplement your profile by adding 
                    additional information about yourself and by posting content and comments. You can 
                    share your profile with a broader audience (such as through social media). Please note 
                    that the Company cannot control the actions of other users of the Services with whom 
                    you share information. </li> 
                <li>Service providers – the Company relies on third-party service providers including 
                    event organisers to perform a variety of services on its behalf and it take steps to 
                    ensure that they act only in line with the Company’s instructions and on its behalf, or 
                    otherwise to use Personal Data as described in this Policy. </li> 
                <li>Third-party advertising partners – the Company shares limited personal information 
                    with third parties that serve its advertising online, so that they can display its adverts 
                    to individuals who may find them most relevant. The Company shares this 
                    information in order to meet its legitimate interests in advertising the Services 
                    effectively. The Company makes sure that it only shares limited Personal Data for this 
                    purpose, and only to trusted partners that are restricted in how they can use your 
                    Personal Data. </li> 
                <li>Other third party partners with your consent – the Company may request your consent 
                    to share Personal Data about you with certain third parties so that they can provide 
                    you with special offers, promotional materials, and other materials that may be of 
                    interest to you. With your consent, the Company also uses and publicises your name, 
                    likeness and other personal characteristics and private information for advertising, 
                    promotion and other commercial and business purposes. </li> 
                <li>Research or surveys – from time to time, the Company will provide your Personal 
                    Data to its service providers to perform research (online and offline) through surveys 
                    on its behalf. Participation is voluntary, and the Company will use the information 
                    that it collects for research and reporting purposes, and to help it to improve the 
                    quality of its Services. The Company uses the survey responses to determine the 
                    effectiveness of its Services, including the quality of its communications and its 
                    advertising and/or promotional activities. If you participate in a survey, your 
                    responses will be used along with other participants’ responses. </li> 
            </ul>
            <p class="p-content">
                The Company may also disclose Personal Data to other third parties, who may be located overseas, including: 
            </p>
            <ul class="p-content">
                <li> third parties engaged by the Company to perform functions or provide products and 
                    services on its behalf, such as: 
                    <ul>
                        <li>distributors of its Services;</li> 
                        <li>event organisers;</li> 
                        <li>hosting, data storage or archiving service providers, payment processing and debt 
                    collection services;</li> 
                        <li>marketing, research and advertising agencies;</li>
                    </ul>
                </li> 
                <li>third parties authorised by you to receive information held by the Company. </li>
            </ul>
            <p class="p-content">
                The Company may impose obligations on any third party with which it shares your Personal 
                Data to maintain the integrity and security of that Personal Data. 
            </p>
            <h4>Personal Data Transfer Abroad </h4>
            <p class="p-content">
                The Company partners with certain third parties to collect, analyse and use some of the 
                Personal Data described in this Policy, for example: 
            </p>
            <ul class="p-content">
                <li>Third-parties – that provide features and functionality on the Services by means of 
                plug-ins. Even if you do not click on or interact with social networking services or 
                other plug-ins, they collect information about you, such as your IP address and the 
                pages that you view. </li>
                <li>Advertising providers – who help the Company and its advertisers provide 
                advertisements on the Services or elsewhere, including advertisements that are targets 
                based on your online behaviour, and analytics companies help the Company measure 
                and evaluate the usage of the Services. </li>
                <li>Other content providers – who offer products and services on the Services and operate 
                contests, or surveys on the Services. </li>
                <li>Cookies and other internet technologies – who collect or receive certain information 
                about your use of the Services, including through the use of cookies, web beacons and 
                internet technologies. Some of these organisations participate in industry-developed 
                programs designed to provide consumers choices about whether to receive targeted 
                advertising. </li>
                <li>Public forums – the Company’s websites may make member networks, chat rooms, 
                forums, and/or other groups available to the users. Remember that anyone can read, 
                collect or use your postings on a network or forum or in a chat room or group and, the 
                Company cannot be responsible for unauthorised third party’s use of information that 
                you disclose online. </li>
                <li>Social media – the Company is active on social media and shares information, images, 
                and videos with the public through unaffiliated external social media sites. The 
                Company encourages you to read their policies. Remember that the social media sites 
                are public, which means that anyone can see your posts, and your posts may even 
                show up in search engine results. Whilst the Company will not edit comments, it may 
                delete a comment from the page on the social media site if it, for example does not 
                relate to the post, promotes a commercial product or service, uses obscene, 
                threatening, or harassing language or violates copyright laws. </li>
            </ul>
            <h4>Cookies </h4>
            <p class="p-content">
                The Company may also use Cookies through its website to maximise your user experience. 
                Unless you have adjusted your browser setting so that it will refuse Cookies, the Company’s 
                system will set Cookies when you load and navigate around its website. By using the 
                Company’s website, you consent to the Company’s use of Cookies under the terms of its 
                policy regarding Cookies.
            </p>
            <p class="p-content">
                 Cookies are text files created on your computer when your web browser loads a website or 
                web application. The Cookies the Company sets helps it to improve its website and to deliver 
                a better and more personalised service, remembering your preferences, and generally 
                improving the user experience 
            </p>
            <p class="p-content">
                You may refuse to accept Cookies by activating the setting on your browser which allows 
                you to refuse the setting of Cookie. Please be aware that if you elect to refuse or restrict 
                Cookies you may be unable to access certain parts of the Company’s website. 
            </p>
            <h4>Anonymity and Pseudonymity </h4>
            <p class="p-content">
                Unless the Company is required by any applicable Singapore law and regulation, or it is 
                otherwise impractical to deal with individuals who have not identified themselves, all 
                individuals have the option of not identifying themselves, or using a pseudonym, when 
                dealing with the Company in relation to a particular matter. 
            </p>
            <h4>Notification of the Collection of Personal Data </h4>
            <p class="p-content">
                If the Company collects Personal Data about you, it will take reasonable steps to notify you of:
            </p> 
            <ul class="p-content">
                <li>the identity and contact details;  </li>
                <li>the fact and circumstances of collection;  </li>
                <li>whether the collection is required or authorised by law;  </li>
                <li>the purposes of the collection;  </li>
                <li>the consequences if Personal Data is not collected;  </li>
                <li>its usual disclosures of Personal Data of that kind;  </li>
                <li>information about the Policy; and,  </li>
                <li>whether it is likely to disclose the information to overseas recipients.  </li>
            </ul>
            <h4>Direct Marketing</h4>
            <p class="p-content">
                The Company will only use or disclose Personal Data to communicate directly with you to 
                promote a good or service:
            </p>
            <ul class="p-content">
                <li>if the Company collected the information directly from you, and you have consented 
                    that the information is to be used in this way; or </li>
                <li>if it is permitted under the applicable Singapore laws and regulations to do so. </li>
            </ul>
            <p class="p-content">
                You will generally be given the opportunity to determine that you do not wish to receive 
                marketing or promotional communications from the Company. Instructions for the aforesaid 
                will typically be included within the communication. 
            </p>
            <h4>Data Quality and Security</h4>
            <p class="p-content">
                The Company takes steps to ensure that the Personal Data it collects is accurate, up to date 
                and complete. These steps include maintaining and updating Personal Data when the 
                Company is advised by individuals that their Personal Data has changed, and at other times 
                when necessary. 
            </p> 
            <p class="p-content">
                The Company will take all reasonable steps to maintain the security and integrity of your 
                Personal Data including the use of computer access passwords, policies, procedures and 
                firewalls
            </p> 
            <h4>Links and Third Party Platforms </h4>
            <p class="p-content">
                Websites operated by the Company may contain links to other sites operated by third parties. 
                The Company makes no representations or warranties as to the privacy practices of any third party site and is not responsible for the privacy policies of other sites. 
            </p> 
            <p class="p-content">
                Sometimes the Company uses third party platforms to deliver information. These are sites 
                hosted and managed by organisations other than the Company. Before deciding if you want 
                to contribute to any third party site, you should read their privacy policy. 
            </p> 
            <h4>Information collected through Employment Applications</h4>
             <p class="p-content">
                In processing applications for employment, the Company collects resumes, references, 
                certificates of graduation and qualification and other Personal Data about candidates. All 
                applications for employment that the Company receives are stored in its Human Resources 
                department. A member of the Human Resources department will review your application. 
                Alternatively, the Company may provide your application to a recruitment agency or 
                contractor who assists in reviewing applications that it receives. 
            </p> 
            <p class="p-content">
                Resumes sent to the Company as part of an application for an advertised position, or sent 
                generally to ascertain whether any positions are available, will be used to match Applicants 
                with available opportunities. If the Company considers that your application may be suitable 
                to its current requirements, it, or a party acting on its behalf, will contact you to request that 
                you attend an interview. The Company may also ask you to provide it with contact 
                information for individuals who will act as professional and personal referees. The Company 
                may contact these individuals and ask them questions it feels are relevant to your possible 
                employment with it. The Company may contact you again to request that you attend further 
                interviews or to inform you of whether it is able to offer you a position. 
            </p> 
            <p class="p-content">
                If your application is not suitable to the Company’s current requirements, but it feels that 
                there may be a position in the future for you with it, the Company will a keep a record of 
                your application and may contact you again if a suitable position becomes available. 
            </p>
            <h4>How you can access and/or correct your Personal Data</h4>
            <p class="p-content">
                You can always, by written request, seek access to your Personal Data and/or ask the 
                Company to correct or delete any inaccurate, incomplete or out of date information or 
                information about the way in which the Personal Data is used or disclosed within a year of 
                such request (or such period as may be required in the PDPA from time to time). Any request for access, correction or deletion of information, or complaint must be addressed to the 
                Company’s Data Protection Officer using the following contact details:
            </p>
            <p class="p-content">
                <strong>By e-mail:</strong> Liu Enxi – liuex@ucommune.com 
            </p>
            <p class="p-content">
                Upon receipt of your request, the Company may request for additional information from you 
                (including proof of identity) in order to process your request. The Company may also charge 
                a reasonable fee to cover administration costs when dealing with any requests for access, 
                which the Company will inform you of in advance. 
            </p>
            <p class="p-content">
                The Company will endeavour to disclose and/or correct such Personal Data in accordance 
                with its legal obligations, and where it refuses to do so, it will, where possible, provide you 
                with the reasons for the refusal.
            </p> 
            <h4>Retention </h4>
            <p class="p-content">
                The Company will retain your Personal Data for as long as you have a relationship with it, 
                and for a period after your relationship with it has ended. When determining how long this 
                retention period will last, the Company takes into account the length of time Personal Data is 
                required to, among other things: 
            </p>
            <ul class="p-content">
                <li>continue to develop, tailor, upgrade and improve the Services; </li>
                <li>maintain business records for analysis and/or audit purposes; </li>
                <li>comply with record retention requirements under the law; </li>
                <li>defend or bring any existing or potential legal claims; or</li> 
                <li>address any complaints regarding the Services.</li>
            </ul> 
            <h4>Information Security </h4>
            <p class="p-content">
                The security of all Personal Data provided to the Company is important. The Company takes 
                reasonable steps to use appropriate measures to protect your Personal Data, including against 
                unauthorised or unlawful processing and accidental loss, destruction or damage. 
                Unfortunately, no data transmission over the internet or storage of information can be 
                guaranteed to be completely secure. As a result, while the Company strives to protect your 
                Personal Data, it cannot ensure or warrant the security of any information that you transmit to 
                the Company, and you do so at your own risk. As such, you should help by implementing 
                your own security controls, such as a strong password, not sharing your personal information 
                with others, logging out when you leave your desk, and other best practices. 
            </p>
            <h4>Complaints and Queries </h4>
            <p class="p-content">
                If you wish to make a complaint about the handling of your Personal Data, or if you have any 
                queries in relation to this Policy, please contact the Data Protection Officer using the contact 
                details set out above.
            </p>
            <p class="p-content"> 
                Upon receipt of any complaints, the Company will carry out an investigation and may contact 
                you for further information. 
            </p>
            <h4>What if the Policy changes?</h4>
            <p class="p-content"> 
                The Company may review, amend or revise its Policy and the way it handles Personal Data 
                from time to time. The Company will post the updated Policy on its website and its terms will 
                take effect from the date of posting. Please check the Policy periodically to inform yourself of 
                any changes. 
            </p>
        </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'

export default {
    name: 'privacyPolicy',
    components: {
        BaseWebPage
    }
}
</script>

<style>
.p-content{
    font-size: 15px;
    line-height: 20px;
}
</style>